
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}
/* .App h1,h2,h3,h4,h5{
  font-family: "americana std", serif;
} */
/* .Collections h1,h2,h3,h4,h5{
  font-family: "Poppins", sans-serif;
} */

.brokerName{
  font-family: "americana std", serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.broker-card {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.perspective {
  perspective: 1000px;
}

.backface-hidden {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.rotateY-180 {
  transform: rotateY(180deg);
}
.perspective {
  perspective: 1000px;
}


.leafletPopup {
  width: 450px !important;
}
.leafletPopup p {
  margin: 0 !important;
}

.slick-dots {
  bottom: -190px; /* Adjust vertical positioning of dots */
  display: flex !important;
  gap: 17px;
}

.slick-dots.slick-thumb {
  display: flex;
  justify-content: center;/* Center thumbnails horizontally */
}

.thumbnail {
  width: 200px; /* Set a specific width for thumbnails */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Rounded corners for a nicer look */
  cursor: pointer; /* Change cursor to pointer */
  overflow: hidden; /* Hide overflow if necessary */
}

.thumbnail:hover {
  opacity: 0.8; /* Slight effect on hover */
}

.slick-dots li button:before {
  color: gray !important; /* Change to your desired inactive dot color */
  opacity: 0.5; /* Optional: Adjust transparency for a subtle effect */
}

/* Active dot */
.slick-dots li.slick-active button:before {
  color: white !important; /* Change to your desired active dot color */
  opacity: 1; /* Ensure the active dot is fully visible */
}


.bar {
  height: 10px;
  width: 100%;
  background-color:rgb(18, 172, 18);
  animation: shrinkWidth 3s forwards linear; /* Animation name, duration, and fill-mode */
}

@keyframes shrinkWidth {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #042c64 #042c64 transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #042c64 #042c64;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    